import * as React from "react";
import { Link, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import DefaultHeader from "../../../components/default-header";
import ExecutiveLeadership from "../../../components/executiveLeadership";
import Footer from "../../../components/footer";
import GdprPanel from "../../../components/gdpr";
import Seo from "../../../components/seo";
import SpeakWithAdmissions from "../../../components/speakWithAdmissions";
import Navigation from "../../../components/navigation";

import "../../../styles/contact-us.scss";
import NewsletterSignup from "../../../components/newsletterSignUp";
import ContactUsForm from "../../../components/forms/contactUsForm";

const Page = ({ data }) => {
  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          type="xl"
          headline={data.mdx.frontmatter.headline}
          heroBackground={data.mdx.frontmatter.heroBackground}
          heroMobileBackground={data.mdx.frontmatter.heroMobileBackground}
          subHeadline={data.mdx.frontmatter.subheadline}
        />
        <div id="body" className="white-bg">
          <Container fluid="lg" className="py-lg-5 py-4">
            <Row className="py-lg-5 py-4">
              <Col xs={12}>
                <div className="medium-light-gray-bg p-xxl-5 p-md-4 p-3 rounded-2">
                  <Row className="align-items-center">
                    <Col xs={12} lg={6}>
                      <Row className="align-items-center">
                        <Col
                          md={2}
                          lg={12}
                          xs={3}
                          className="mb-3 pb-1 mb-md-0 pb-md-0"
                        >
                          <div className="icon-background mb-lg-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="29"
                              height="29"
                              viewBox="0 0 29 29"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_2156_10411)">
                                <path
                                  d="M24.8854 4H3.30208C1.53225 4 0.09375 5.4385 0.09375 7.20833V21.7917C0.09375 23.5615 1.53225 25 3.30208 25H24.8854C26.6552 25 28.0938 23.5615 28.0938 21.7917V7.20833C28.0938 5.4385 26.6552 4 24.8854 4ZM8.84375 8.66667C10.4514 8.66667 11.7604 9.97567 11.7604 11.5833C11.7604 13.191 10.4514 14.5 8.84375 14.5C7.23608 14.5 5.92708 13.191 5.92708 11.5833C5.92708 9.97567 7.23608 8.66667 8.84375 8.66667ZM14.0938 19.4583C14.0938 19.9413 13.7017 20.3333 13.2188 20.3333H4.46875C3.98575 20.3333 3.59375 19.9413 3.59375 19.4583V18.875C3.59375 17.1052 5.03225 15.6667 6.80208 15.6667H10.8854C12.6552 15.6667 14.0938 17.1052 14.0938 18.875V19.4583ZM23.7188 20.3333H17.3021C16.8191 20.3333 16.4271 19.9413 16.4271 19.4583C16.4271 18.9753 16.8191 18.5833 17.3021 18.5833H23.7188C24.2017 18.5833 24.5938 18.9753 24.5938 19.4583C24.5938 19.9413 24.2017 20.3333 23.7188 20.3333ZM23.7188 15.6667H17.3021C16.8191 15.6667 16.4271 15.2747 16.4271 14.7917C16.4271 14.3087 16.8191 13.9167 17.3021 13.9167H23.7188C24.2017 13.9167 24.5938 14.3087 24.5938 14.7917C24.5938 15.2747 24.2017 15.6667 23.7188 15.6667ZM23.7188 11H17.3021C16.8191 11 16.4271 10.608 16.4271 10.125C16.4271 9.642 16.8191 9.25 17.3021 9.25H23.7188C24.2017 9.25 24.5938 9.642 24.5938 10.125C24.5938 10.608 24.2017 11 23.7188 11Z"
                                  fill="#323337"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2156_10411">
                                  <rect
                                    width="28"
                                    height="28"
                                    fill="white"
                                    transform="translate(0.09375 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </Col>
                        <Col
                          md={10}
                          xl={12}
                          xs={9}
                          className="mb-3 pb-1 mb-md-0 pb-md-0"
                        >
                          <h2 className="section-title">
                            Admissions and Financial Aid
                          </h2>
                        </Col>
                        <Col lg={9}>
                          <p className="section-description">
                            Our Admissions Advisors are available Monday through
                            Friday, 9am to 5pm PT via voice and email.
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                      <div className="white-background">
                        <Row>
                          <Col xs={12} className="mb-4">
                            <div className="contact-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                              >
                                <path
                                  d="M20.0438 21.5C17.9604 21.5 15.9021 21.0458 13.8688 20.1375C11.8354 19.2292 9.98542 17.9417 8.31875 16.275C6.65208 14.6083 5.36458 12.7583 4.45625 10.725C3.54792 8.69167 3.09375 6.63333 3.09375 4.55C3.09375 4.25 3.19375 4 3.39375 3.8C3.59375 3.6 3.84375 3.5 4.14375 3.5H8.19375C8.42708 3.5 8.63542 3.57917 8.81875 3.7375C9.00208 3.89583 9.11042 4.08333 9.14375 4.3L9.79375 7.8C9.82708 8.06667 9.81875 8.29167 9.76875 8.475C9.71875 8.65833 9.62708 8.81667 9.49375 8.95L7.06875 11.4C7.40208 12.0167 7.79792 12.6125 8.25625 13.1875C8.71458 13.7625 9.21875 14.3167 9.76875 14.85C10.2854 15.3667 10.8271 15.8458 11.3938 16.2875C11.9604 16.7292 12.5604 17.1333 13.1938 17.5L15.5437 15.15C15.6938 15 15.8896 14.8875 16.1313 14.8125C16.3729 14.7375 16.6104 14.7167 16.8438 14.75L20.2938 15.45C20.5271 15.5167 20.7188 15.6375 20.8687 15.8125C21.0188 15.9875 21.0938 16.1833 21.0938 16.4V20.45C21.0938 20.75 20.9937 21 20.7938 21.2C20.5938 21.4 20.3438 21.5 20.0438 21.5Z"
                                  fill="#0078AE"
                                />
                              </svg>
                            </div>
                            <a
                              href="tel:+1(833)256-2295"
                              className="contact-link"
                            >
                              +1 (833) 256-2295
                            </a>
                          </Col>
                          <Col xs={12} className="mb-4">
                            <p className="contact-text mb-2">
                              Contact an Admissions Advisor:
                            </p>
                            <div className="contact-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                              >
                                <path
                                  d="M4.09375 20.499C3.54375 20.499 3.07292 20.3032 2.68125 19.9115C2.28958 19.5199 2.09375 19.049 2.09375 18.499V6.49902C2.09375 5.94902 2.28958 5.47819 2.68125 5.08652C3.07292 4.69486 3.54375 4.49902 4.09375 4.49902H20.0938C20.6438 4.49902 21.1146 4.69486 21.5063 5.08652C21.8979 5.47819 22.0938 5.94902 22.0938 6.49902V18.499C22.0938 19.049 21.8979 19.5199 21.5063 19.9115C21.1146 20.3032 20.6438 20.499 20.0938 20.499H4.09375ZM12.0938 13.324C12.1771 13.324 12.2646 13.3115 12.3562 13.2865C12.4479 13.2615 12.5354 13.224 12.6188 13.174L19.6938 8.74902C19.8271 8.66569 19.9271 8.56152 19.9937 8.43652C20.0604 8.31152 20.0938 8.17402 20.0938 8.02402C20.0938 7.69069 19.9521 7.44069 19.6688 7.27402C19.3854 7.10736 19.0938 7.11569 18.7938 7.29902L12.0938 11.499L5.39375 7.29902C5.09375 7.11569 4.80208 7.11152 4.51875 7.28652C4.23542 7.46152 4.09375 7.70736 4.09375 8.02402C4.09375 8.19069 4.12708 8.33652 4.19375 8.46152C4.26042 8.58652 4.36042 8.68236 4.49375 8.74902L11.5688 13.174C11.6521 13.224 11.7396 13.2615 11.8313 13.2865C11.9229 13.3115 12.0104 13.324 12.0938 13.324Z"
                                  fill="#0078AE"
                                />
                              </svg>
                            </div>
                            <a
                              href="mailto:admissions@meridianuniversity.edu"
                              className="contact-link"
                            >
                              admissions
                              <span className="d-md-none">
                                <br />
                              </span>
                              @meridianuniversity.edu
                            </a>
                          </Col>
                          <Col xs={12} className="mb-4">
                            <p className="contact-text mb-2">
                              Contact a Financial Aid Advisor:
                            </p>
                            <div className="contact-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                              >
                                <path
                                  d="M4.09375 20.499C3.54375 20.499 3.07292 20.3032 2.68125 19.9115C2.28958 19.5199 2.09375 19.049 2.09375 18.499V6.49902C2.09375 5.94902 2.28958 5.47819 2.68125 5.08652C3.07292 4.69486 3.54375 4.49902 4.09375 4.49902H20.0938C20.6438 4.49902 21.1146 4.69486 21.5063 5.08652C21.8979 5.47819 22.0938 5.94902 22.0938 6.49902V18.499C22.0938 19.049 21.8979 19.5199 21.5063 19.9115C21.1146 20.3032 20.6438 20.499 20.0938 20.499H4.09375ZM12.0938 13.324C12.1771 13.324 12.2646 13.3115 12.3562 13.2865C12.4479 13.2615 12.5354 13.224 12.6188 13.174L19.6938 8.74902C19.8271 8.66569 19.9271 8.56152 19.9937 8.43652C20.0604 8.31152 20.0938 8.17402 20.0938 8.02402C20.0938 7.69069 19.9521 7.44069 19.6688 7.27402C19.3854 7.10736 19.0938 7.11569 18.7938 7.29902L12.0938 11.499L5.39375 7.29902C5.09375 7.11569 4.80208 7.11152 4.51875 7.28652C4.23542 7.46152 4.09375 7.70736 4.09375 8.02402C4.09375 8.19069 4.12708 8.33652 4.19375 8.46152C4.26042 8.58652 4.36042 8.68236 4.49375 8.74902L11.5688 13.174C11.6521 13.224 11.7396 13.2615 11.8313 13.2865C11.9229 13.3115 12.0104 13.324 12.0938 13.324Z"
                                  fill="#0078AE"
                                />
                              </svg>
                            </div>
                            <a
                              href="mailto:financialaid@meridianuniversity.edu"
                              className="contact-link"
                            >
                              financialaid
                              <span className="d-md-none">
                                <br />
                              </span>
                              @meridianuniversity.edu
                            </a>
                          </Col>
                          <Col xs={12} md={6} lg={12} className="border p-3">
                            <Row className="align-items-center">
                              <Col xs={12} xl={6}>
                                <p className="contact-text mb-xl-0">
                                  Schedule an Appointment with Admissions:
                                </p>
                              </Col>
                              <Col xs={12} xl={6}>
                                <Link
                                  to="/admissions/graduate-admissions/schedule-with-admissions"
                                  className="btn btn-contact blue"
                                >
                                  Book A Call{" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_2154_9911)">
                                      <path
                                        d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                        fill="#323337"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_2154_9911">
                                        <rect
                                          width="12"
                                          height="12"
                                          fill="white"
                                          transform="matrix(-1 0 0 1 12.0938 0.5)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </Link>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-5" />
                  <Row className="align-items-center">
                    <Col xs={12} lg={6}>
                      <Row className="align-items-center">
                        <Col
                          xs={3}
                          md={2}
                          lg={12}
                          className="mb-3 pb-1 mb-md-0 pb-md-0"
                        >
                          <div className="icon-background mb-lg-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="29"
                              height="28"
                              viewBox="0 0 29 28"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_2208_9527)">
                                <path
                                  d="M14.0938 15.75C16.0267 15.75 17.5938 14.183 17.5938 12.25C17.5938 10.317 16.0267 8.75 14.0938 8.75C12.1608 8.75 10.5938 10.317 10.5938 12.25C10.5938 14.183 12.1608 15.75 14.0938 15.75Z"
                                  fill="#323337"
                                />
                                <path
                                  d="M27.2188 21.875H14.9688C14.4858 21.875 14.0938 22.267 14.0938 22.75V24.5C14.0938 24.983 14.4858 25.375 14.9688 25.375H27.2188C27.7017 25.375 28.0938 24.983 28.0938 24.5V22.75C28.0938 22.267 27.7017 21.875 27.2188 21.875Z"
                                  fill="#323337"
                                />
                                <path
                                  d="M27.2188 16.625H14.9688C14.4858 16.625 14.0938 17.017 14.0938 17.5V19.25C14.0938 19.733 14.4858 20.125 14.9688 20.125H27.2188C27.7017 20.125 28.0938 19.733 28.0938 19.25V17.5C28.0938 17.017 27.7017 16.625 27.2188 16.625Z"
                                  fill="#323337"
                                />
                                <path
                                  d="M26.3438 2.625H1.84375C0.8795 2.625 0.09375 3.41075 0.09375 4.375V20.125C0.09375 21.091 0.8795 21.875 1.84375 21.875H12.3438V20.125H7.0045C6.61775 17.4528 4.516 15.351 1.84375 14.9642V9.53575C4.516 9.149 6.61775 7.04725 7.0045 4.375H21.1812C21.5697 7.04725 23.6715 9.149 26.3438 9.53575V14.875H28.0938V4.375C28.0938 3.41075 27.3097 2.625 26.3438 2.625Z"
                                  fill="#323337"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2208_9527">
                                  <rect
                                    width="28"
                                    height="28"
                                    fill="white"
                                    transform="translate(0.09375)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </Col>
                        <Col
                          xs={9}
                          md={2}
                          lg={12}
                          className="mb-3 pb-1 mb-md-0 pb-md-0"
                        >
                          <h2 className="section-title">Financial Services</h2>
                        </Col>
                        <Col lg={9}>
                          <p className="section-description">
                            Students can contact the Financial Services Team for
                            all financial aid and billing inquiries.
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                      <div className="white-background">
                        <Row className="justify-content-evenly">
                          <Col
                            xs={12}
                            md={6}
                            lg={12}
                            xl={6}
                            className="mb-2 mb-xl-0 mb-md-0 mb-lg-2"
                          >
                            <div className="border p-3">
                              <p className="contact-text mb-3">
                                Schedule an Appointment with Admissions:
                              </p>
                              <Link
                                to="/admissions/graduate-admissions/schedule-with-admissions"
                                className="btn btn-contact blue"
                              >
                                Book A Call{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 13"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_2154_9911)">
                                    <path
                                      d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                      fill="#323337"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2154_9911">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                        transform="matrix(-1 0 0 1 12.0938 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </Link>
                            </div>
                          </Col>
                          <Col xs={12} md={6} lg={12} xl={6}>
                            <div className="border p-3">
                              <p className="contact-text mb-2 me-md-4 me-lg-0">
                                Review Financial Services Procedures
                              </p>
                              <Link
                                to="/admissions/graduate-admissions/schedule-with-admissions"
                                className="btn btn-contact green"
                              >
                                Review{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 13"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_2154_9911)">
                                    <path
                                      d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                      fill="#323337"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2154_9911">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                        transform="matrix(-1 0 0 1 12.0938 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-5" />
                  <Row className="align-items-center">
                    <Col xs={12} xl={6} className="mb-xxl-5 mb-4">
                      <div className="white-background">
                        <Row className="justify-content-evenly">
                          <Col xs={12} lg={5} xl={12} className="mb-4">
                            <h2 className="section-title">Student Services</h2>
                            <p className="section-description">
                              Students can request services via email or request
                              a phone call.
                            </p>
                          </Col>
                          <Col xs={12} lg={7} xl={12}>
                            <Row>
                              <Col xs={12} md={6} className="mb-2 mb-md-0">
                                <div className="border p-3">
                                  <p className="contact-text mb-3">
                                    Submit a Student Services Request
                                  </p>
                                  <a href="#" className="btn btn-contact blue">
                                    Submit{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13"
                                      height="13"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_2154_9911)">
                                        <path
                                          d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                          fill="#323337"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2154_9911">
                                          <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="matrix(-1 0 0 1 12.0938 0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </a>
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="border p-3">
                                  <p className="contact-text mb-3">
                                    <span className="d-none d-xxl-block">
                                      <br />
                                    </span>
                                    Access the Knowledge Base
                                  </p>
                                  <a
                                    href="https://support.meridianuniversity.edu/"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="btn btn-contact green"
                                  >
                                    Go To Base{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13"
                                      height="13"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_2154_9911)">
                                        <path
                                          d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                          fill="#323337"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2154_9911">
                                          <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="matrix(-1 0 0 1 12.0938 0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </a>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={12} xl={6} className="mb-xxl-5 mb-4">
                      <div className="white-background">
                        <Row className="justify-content-evenly">
                          <Col
                            xs={12}
                            lg={5}
                            xl={12}
                            className="mb-4 mb-lg-0 mb-xl-4"
                          >
                            <h2 className="section-title">Library Services</h2>
                            <p className="section-description">
                              Students and faculty can contact the Scholarship
                              Team for library services and research support.
                              <span className="d-none d-xxl-block">
                                <br />
                                &nbsp;
                              </span>
                            </p>
                          </Col>
                          <Col xs={12} lg={7} xl={12}>
                            <Row>
                              <Col xs={12} md={6} className="mb-2 mb-md-0">
                                <div className="border p-3">
                                  <p className="contact-text mb-3">
                                    Submit a Library Request
                                    <span className="d-none d-lg-block d-xl-none">
                                      <br />
                                    </span>
                                  </p>
                                  <a href="#" className="btn btn-contact blue">
                                    Submit{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13"
                                      height="13"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_2154_9911)">
                                        <path
                                          d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                          fill="#323337"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2154_9911">
                                          <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="matrix(-1 0 0 1 12.0938 0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </a>
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="border p-3">
                                  <p className="contact-text mb-3">
                                    Browse Library Resources
                                  </p>
                                  <a
                                    href="/admissions/graduate-admissions/schedule-with-admissions"
                                    className="btn btn-contact green"
                                  >
                                    Library{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13"
                                      height="13"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_2154_9911)">
                                        <path
                                          d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                          fill="#323337"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2154_9911">
                                          <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="matrix(-1 0 0 1 12.0938 0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </a>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={12} xl={6} className="mb-xxl-5 mb-4">
                      <div className="white-background">
                        <Row className="justify-content-evenly">
                          <Col xs={12} md={6} lg={8} xl={12} className="mb-4">
                            <h2 className="section-title">Academic Advising</h2>
                            <p className="section-description mb-md-0 mb-lg-3">
                              Students can meet with their faculty advisor for
                              academic advising by scheduling an appointment.
                            </p>
                          </Col>
                          <Col xs={12} md={6} lg={4} xl={12}>
                            <Row>
                              <Col xs={12}>
                                <div className="border p-3">
                                  <Row>
                                    <Col xs={12} xl={6}>
                                      <p className="contact-text mb-xl-0">
                                        Schedule an Academic Advising
                                        Appointment
                                      </p>
                                    </Col>
                                    <Col xs={12} xl={6}>
                                      <Link
                                        to="/admissions/graduate-admissions/schedule-with-admissions"
                                        className="btn btn-contact blue"
                                      >
                                        Book A Call{" "}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13"
                                          height="13"
                                          viewBox="0 0 13 13"
                                          fill="none"
                                        >
                                          <g clipPath="url(#clip0_2154_9911)">
                                            <path
                                              d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                              fill="#323337"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_2154_9911">
                                              <rect
                                                width="12"
                                                height="12"
                                                fill="white"
                                                transform="matrix(-1 0 0 1 12.0938 0.5)"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </Link>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={12} xl={6} className="mb-xxl-5 mb-4">
                      <div className="white-background">
                        <Row className="justify-content-evenly">
                          <Col xs={12} lg={5} xl={12} className="mb-4">
                            <h2 className="section-title">Alumni Services</h2>
                          </Col>
                          <Col xs={12} lg={7} xl={12}>
                            <Row>
                              <Col xs={12} md={6} className="mb-2 mb-md-0">
                                <div className="border p-3">
                                  <p className="contact-text mb-3">
                                    <span className="d-none d-xxl-block">
                                      <br />
                                    </span>
                                    Submit a Transcript Request
                                  </p>
                                  <a href="#" className="btn btn-contact blue">
                                    Submit{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13"
                                      height="13"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_2154_9911)">
                                        <path
                                          d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                          fill="#323337"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2154_9911">
                                          <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="matrix(-1 0 0 1 12.0938 0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </a>
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="border p-3">
                                  <p className="contact-text mb-3">
                                    Submit an Academic Records Inquiry
                                  </p>
                                  <a
                                    href="/admissions/graduate-admissions/schedule-with-admissions"
                                    className="btn btn-contact green"
                                  >
                                    Library{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13"
                                      height="13"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_2154_9911)">
                                        <path
                                          d="M3.94916 0.5L9.94922 6.5L3.94916 12.5L2.24711 10.7978L6.54499 6.5L2.24711 2.20217L3.94916 0.5Z"
                                          fill="#323337"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2154_9911">
                                          <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="matrix(-1 0 0 1 12.0938 0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </a>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="grey-background for-all-other">
                        <p>
                          Faculty email addresses and phone numbers are
                          accessible via course syllabi.
                        </p>
                        <p className="mb-0">
                          For all other inquiries, contact{" "}
                          <a href="mailto:info@meridianuniversity.edu">
                            info@meridianuniversity.edu
                          </a>
                          . This address is not for prospective students,
                          current students, or alumni.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="pt-lg-5 pt-4">
              <Col xs={12} className="p-custom">
                <div
                  id="bottom-contact"
                  className="contact-us-bottom p-xxl-5 p-md-4 p-3 py-4"
                >
                  <Row>
                    <Col lg={6} xs={12} className="pe-xxl-4">
                      <h2 className="bottom-contact-us-title mb-4">
                        Connect with an Admissions Advisor
                      </h2>
                      <p className="bottom-contact-us-description mb-4 mb-lg-0">
                        Interested in exploring your future at Meridian? Connect
                        with a member of our Admissions Team to understand
                        options, imagine your professional future, and arrange a
                        time to meet program faculty.
                      </p>
                    </Col>
                    <Col lg={6} xs={12} className="ps-xxl-4">
                      <ContactUsForm
                        submitType="lead"
                        submitCampaignID="7013i000000piXBAAY"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
};

export const Head = ({ data }) => (
  <Seo
    title={data.mdx.frontmatter.metadata.Title}
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
    ogImage={data.mdx.frontmatter.metadata.ogImage}
    cardImage={data.mdx.frontmatter.metadata.cardImage}
  />
);

export const query = graphql`
  query ContactUsPageQuery {
    mdx(fileAbsolutePath: { regex: "/contact-us.mdx/" }) {
      frontmatter {
        heroBackground
        heroMobileBackground
        subheadline
        headline
        title
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
      }
      body
    }
  }
`;

export default Page;
